import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ color: !_ctx.valid ? '#B3B3B3' : _ctx.valid ? '#02B15D' : '#A82E2E' })
  }, [
    _createVNode(_component_font_awesome_icon, {
      icon: _ctx.valid ? 'fa-solid fa-check' : 'fa-solid fa-xmark',
      style: _normalizeStyle({ color: !_ctx.valid ? '#B3B3B3' : _ctx.valid ? '#02B15D' : '#A82E2E' })
    }, null, 8, ["icon", "style"]),
    _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
  ], 4))
}