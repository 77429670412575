import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "help-text",
  style: {"width":"350px"}
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_form_invalid_feedback = _resolveComponent("b-form-invalid-feedback")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.capitalizeFirstLetter(_ctx.$t('password')) + ' ' + _ctx.$t('forgot')), 1),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.requestSended ? _ctx.$t('resetPasswordTextSuccess') : _ctx.$t('resetPasswordHelpText')), 1),
    (!_ctx.requestSended)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_b_form, {
            onSubmit: _withModifiers(_ctx.onSubmit, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_form_group, {
                label: _ctx.capitalizeFirstLetter(_ctx.$t('mail')),
                "label-for": "input-email"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_input, {
                    id: "input-email",
                    modelValue: _ctx.form.login,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.login) = $event)),
                    type: "email",
                    placeholder: _ctx.$t('enterYourEmail'),
                    required: ""
                  }, null, 8, ["modelValue", "placeholder"]),
                  _createVNode(_component_b_form_invalid_feedback, {
                    state: _ctx.validation.login.state
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.validation.login.message), 1)
                    ], undefined, true),
                    _: 1
                  }, 8, ["state"])
                ], undefined, true),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_b_button, {
                size: "lg",
                class: "w-100",
                variant: "secondary",
                style: _normalizeStyle(_ctx.validation.login.state ? _ctx.buttonStyle : ''),
                disabled: !_ctx.validation.login.state,
                onClick: _ctx.onSubmit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('sendPasswordResetLink')), 1)
                ], undefined, true),
                _: 1
              }, 8, ["style", "disabled", "onClick"])
            ], undefined, true),
            _: 1
          }, 8, ["onSubmit"]),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("a", {
              class: "text-secondary return-login",
              "href.prevent": "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-switch')))
            }, null, 512), [
              [_directive_t, 'return']
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}