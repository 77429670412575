import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "fsb-dropdown__line__label" }

import { ref } from 'vue';
import { computed } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'dropdownItem',
  props: {
    imgUrl: String,
    imgHoverUrl: String,
    text: String,
    selected: Boolean,
},
  setup(__props) {

const props = __props;

const hover = ref(false);

const url = computed(() => {
    if(props.selected) return props.imgUrl;
    return hover.value ? props.imgHoverUrl : props.imgUrl; 
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex align-items-center h-100",
    style: {"width":"auto"},
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (hover.value = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (hover.value = false))
  }, [
    (__props.imgUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          width: "16",
          height: "16",
          src: url.value,
          alt: ""
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(__props.text), 1)
  ], 32))
}
}

})