<template>
  <div class="custom-password-input">
    <input
      :id="id"
      :value="modelValue"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      @input="handleInput"
    />
    <a href.prevent @click="isPassword = !isPassword">{{ text }}</a>
  </div>
</template>

<script setup lang="ts">
import { capitalizeFirstLetter } from '@/helpers/tools';
import { computed, defineEmits, defineProps, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const isPassword = ref(true);

defineProps({
  id: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  modelValue: {
    required: true,
    type: String,
  },
});

const emit = defineEmits(['update:modelValue', 'test']);

const handleInput = (event) => {
  const value = (event.target as HTMLInputElement).value;
  emit('update:modelValue', value);
  emit('test', value);
};

const type = computed(() => {
  return isPassword.value ? 'password' : 'text';
});

const text = computed(() => {
  let text = isPassword.value ? 'display' : 'hide';
  return capitalizeFirstLetter(t(text));
});
</script>

<style lang="scss">
.custom-password-input {
  position: relative;
  a {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}
</style>
