<template>
    <h1>
        {{ capitalizeFirstLetter($t('Connexion')) }}
    </h1>
    <div class="help-text">
        {{ $t('recommendedBrowser') }}
        <a href="https://www.google.fr/chrome/">Google Chrome</a>
        {{ $t('or') }}
        <a href="https://www.mozilla.org/">Mozilla Firefox.</a>
    </div>

    <b-form @submit="onSubmit" @keydown.enter="onSubmit" class="login-view">
        <custom-alert :show="errorStatus !== null" variant="danger">
            <span v-if="errorStatus === 400 || errorStatus === 401" v-t="'loginBadRequest'"></span>
            <span v-else v-t="'serverError'"></span>
        </custom-alert>
        <div class="mb-4">
            <simple-input
                id="input-email"
                required
                type="email"
                :label="capitalizeFirstLetter($t('email'))"
                :placeholder="t('enterEmail')"
                :invalid="!emailValidation.state"
                @update:value="updateEmail"
            ></simple-input>
            <b-form-invalid-feedback :state="emailValidation.state">{{ emailValidation.message }}</b-form-invalid-feedback>
        </div>

        <password-input class="mb-4" id="password-input" :label="capitalizeFirstLetter($t('password'))" :placeholder="t('enterPassword')" required @update:value="updatePassword"></password-input>

        <b-button class="w-100" size="lg" block variant="secondary" :style="buttonDisabled ? '' : buttonStyle" @click="onSubmit" :disabled="buttonDisabled">
            <b-spinner v-if="loading" small></b-spinner>
            {{ $t('toLog') }}
        </b-button>

        <div class="d-flex justify-content-between mb-4 pt-3">
            <div class="forget-password">
                <a class="text-secondary" href.prevent @click="$emit('on-switch')" v-t="'forgetPassword'"></a>
            </div>
        </div>
    </b-form>
</template>

<script lang="ts" setup>
import { simpleFormValidation } from '@/compositions/formValidation';
import { capitalizeFirstLetter } from '@/helpers/tools';
import { useAuthStore } from '@/store';
import { computed, defineProps, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps<{
    buttonStyle: Record<string, string | number | undefined>;
}>();

const loading = ref(false);
const errorStatus = ref(null);

const authStore = useAuthStore();
const { t } = useI18n();

let form = reactive({
    login: '' as string,
    password: '' as string,
});
let rules = {
    login: {
        rule: 'required=true|type=email' as string,
        message: t('emailMustBe'),
    },
    password: {
        rule: 'required=true' as string,
        message: '' as string,
    },
};

const emailValidation = ref<{
    state: null | boolean;
    message: string;
}>({
    state: null,
    message: '',
});

const passwordValidation = ref<{
    state: null | boolean;
    message: string;
}>({
    state: null,
    message: '',
});

const updatePassword = (val: string) => {
    form.password = val;
};
const updateEmail = ({ value }: { value: string }) => {
    form.login = value;
};

const buttonDisabled = computed(() => {
    return loading.value || emailValidation.value.state === false || passwordValidation.value.state === false;
});

watch([() => form.login, () => form.password], () => {
    emailValidation.value = simpleFormValidation(rules.login, form.login, t);
    passwordValidation.value = simpleFormValidation(rules.password, form.password, t);
    errorStatus.value = null;
});

const onSubmit = async () => {
    if (buttonDisabled.value) return;

    loading.value = true;
    try {
        await authStore.login(form);
    } catch (e: any) {
        errorStatus.value = e.statusCode;
        console.error(e);
        emailValidation.value.state = false;
        passwordValidation.value.state = false;
    }
    loading.value = false;
};
</script>

<style lang="scss" scoped>
#input-password {
    background-image: none !important;
}
.login-view {
    .help-text {
        a {
            font-weight: bold;
            text-decoration: none;
        }
    }

    .forget-password {
        text-decoration: underline !important;
        cursor: pointer;
    }
}

.help-text,
a {
    color: #707070;
}

.password-btn {
    display: flex;
    background: transparent;
    border: none;
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    right: 3.5px;
}
</style>
