<template>
    <div class="iframe-container">
        <template v-for="child of appChildren" :key="child.url + child.name">
            <iframe
                :src="child.url"
                :data-id="child.url + child.name"
                frameborder="0"
                v-show="child.show"
                ref="appChildrenDomEl"
            ></iframe>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import { useGlobal } from '../../store';
const globalStore = useGlobal();
const appChildren = reactive(globalStore.appChildren);
</script>

<style lang="scss">
.iframe-container {
    width: 100%;

    iframe {
        height: 100%;
        width: 100%;
    }
}
</style>
