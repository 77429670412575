<template>
    <login-layout :brand="customizationConfig ? globalStore.selectedBrand : undefined">
        <div class="m-auto" style="width: 416px; height: 340px">
            <reset-pwd v-if="resetPassword" :button-style="buttonStyle" @on-switch="resetPassword = false" />
            <login v-else :button-style="buttonStyle" @on-switch="resetPassword = true" />
        </div>
    </login-layout>
</template>

<script lang="ts" setup>
import { useGlobal } from '@/store';
import chroma from 'chroma-js';
import { computed, ref } from 'vue';
import LoginLayout from '../layout/login-layout.vue';
import Login from './login-index.vue';
import ResetPwd from './reset-pwd.vue';

const globalStore = useGlobal();

const resetPassword = ref(false);
const customizationConfig = ref(globalStore.customizationByBrand[globalStore.selectedBrand]);

const buttonStyle = computed(() => {
    if (customizationConfig.value) {
        return {
            backgroundColor: `${customizationConfig.value.color} !important`,
            borderColor: `${customizationConfig.value.color} !important`,
            color: `${chroma(customizationConfig.value.color).luminance() > 0.5 ? 'var(--grayscale-black, #1f1f1f)' : 'var(--grayscale-white, #fefefe)'} !important`,
        };
    }
    return {};
});
</script>
