import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withKeys as _withKeys, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "help-text" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = { class: "d-flex justify-content-between mb-4 pt-3" }
const _hoisted_6 = { class: "forget-password" }

import { simpleFormValidation } from '@/compositions/formValidation';
import { capitalizeFirstLetter } from '@/helpers/tools';
import { useAuthStore } from '@/store';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'login-index',
  props: {
    buttonStyle: {}
  },
  setup(__props: any) {



const loading = ref(false);
const errorStatus = ref(null);

const authStore = useAuthStore();
const { t } = useI18n();

let form = reactive({
    login: '' as string,
    password: '' as string,
});
let rules = {
    login: {
        rule: 'required=true|type=email' as string,
        message: t('emailMustBe'),
    },
    password: {
        rule: 'required=true' as string,
        message: '' as string,
    },
};

const emailValidation = ref<{
    state: null | boolean;
    message: string;
}>({
    state: null,
    message: '',
});

const passwordValidation = ref<{
    state: null | boolean;
    message: string;
}>({
    state: null,
    message: '',
});

const updatePassword = (val: string) => {
    form.password = val;
};
const updateEmail = ({ value }: { value: string }) => {
    form.login = value;
};

const buttonDisabled = computed(() => {
    return loading.value || emailValidation.value.state === false || passwordValidation.value.state === false;
});

watch([() => form.login, () => form.password], () => {
    emailValidation.value = simpleFormValidation(rules.login, form.login, t);
    passwordValidation.value = simpleFormValidation(rules.password, form.password, t);
    errorStatus.value = null;
});

const onSubmit = async () => {
    if (buttonDisabled.value) return;

    loading.value = true;
    try {
        await authStore.login(form);
    } catch (e: any) {
        errorStatus.value = e.statusCode;
        console.error(e);
        emailValidation.value.state = false;
        passwordValidation.value.state = false;
    }
    loading.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_custom_alert = _resolveComponent("custom-alert")!
  const _component_simple_input = _resolveComponent("simple-input")!
  const _component_b_form_invalid_feedback = _resolveComponent("b-form-invalid-feedback")!
  const _component_password_input = _resolveComponent("password-input")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form = _resolveComponent("b-form")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", null, _toDisplayString(_unref(capitalizeFirstLetter)(_ctx.$t('Connexion'))), 1),
    _createElementVNode("div", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.$t('recommendedBrowser')) + " ", 1),
      _cache[1] || (_cache[1] = _createElementVNode("a", { href: "https://www.google.fr/chrome/" }, "Google Chrome", -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('or')) + " ", 1),
      _cache[2] || (_cache[2] = _createElementVNode("a", { href: "https://www.mozilla.org/" }, "Mozilla Firefox.", -1))
    ]),
    _createVNode(_component_b_form, {
      onSubmit: onSubmit,
      onKeydown: _withKeys(onSubmit, ["enter"]),
      class: "login-view"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_custom_alert, {
          show: errorStatus.value !== null,
          variant: "danger"
        }, {
          default: _withCtx(() => [
            (errorStatus.value === 400 || errorStatus.value === 401)
              ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, null, 512)), [
                  [_directive_t, 'loginBadRequest']
                ])
              : _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, null, 512)), [
                  [_directive_t, 'serverError']
                ])
          ], undefined, true),
          _: 1
        }, 8, ["show"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_simple_input, {
            id: "input-email",
            required: "",
            type: "email",
            label: _unref(capitalizeFirstLetter)(_ctx.$t('email')),
            placeholder: _unref(t)('enterEmail'),
            invalid: !emailValidation.value.state,
            "onUpdate:value": updateEmail
          }, null, 8, ["label", "placeholder", "invalid"]),
          _createVNode(_component_b_form_invalid_feedback, {
            state: emailValidation.value.state
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(emailValidation.value.message), 1)
            ], undefined, true),
            _: 1
          }, 8, ["state"])
        ]),
        _createVNode(_component_password_input, {
          class: "mb-4",
          id: "password-input",
          label: _unref(capitalizeFirstLetter)(_ctx.$t('password')),
          placeholder: _unref(t)('enterPassword'),
          required: "",
          "onUpdate:value": updatePassword
        }, null, 8, ["label", "placeholder"]),
        _createVNode(_component_b_button, {
          class: "w-100",
          size: "lg",
          block: "",
          variant: "secondary",
          style: _normalizeStyle(buttonDisabled.value ? '' : _ctx.buttonStyle),
          onClick: onSubmit,
          disabled: buttonDisabled.value
        }, {
          default: _withCtx(() => [
            (loading.value)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('toLog')), 1)
          ], undefined, true),
          _: 1
        }, 8, ["style", "disabled"]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("a", {
              class: "text-secondary",
              "href.prevent": "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-switch')))
            }, null, 512), [
              [_directive_t, 'forgetPassword']
            ])
          ])
        ])
      ], undefined, true),
      _: 1
    })
  ], 64))
}
}

})