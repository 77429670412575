import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-password-input" }
const _hoisted_2 = ["id", "value", "type", "placeholder", "required"]

import { capitalizeFirstLetter } from '@/helpers/tools';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'inputPassword',
  props: {
  id: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  modelValue: {
    required: true,
    type: String,
  },
},
  emits: ['update:modelValue', 'test'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const isPassword = ref(true);



const emit = __emit;

const handleInput = (event) => {
  const value = (event.target as HTMLInputElement).value;
  emit('update:modelValue', value);
  emit('test', value);
};

const type = computed(() => {
  return isPassword.value ? 'password' : 'text';
});

const text = computed(() => {
  let text = isPassword.value ? 'display' : 'hide';
  return capitalizeFirstLetter(t(text));
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: __props.id,
      value: __props.modelValue,
      type: type.value,
      placeholder: __props.placeholder,
      required: __props.required,
      onInput: handleInput
    }, null, 40, _hoisted_2),
    _createElementVNode("a", {
      "href.prevent": "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isPassword.value = !isPassword.value))
    }, _toDisplayString(text.value), 1)
  ]))
}
}

})