<template>
    <div class="d-flex align-items-center h-100" style="width: auto;" @mouseover="hover = true" @mouseleave="hover = false">
        <img
            v-if="imgUrl"
            width="16"
            height="16"
            :src="url"
            alt=""
        />
        <div class="fsb-dropdown__line__label">{{ text }}</div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { defineProps, computed } from 'vue';
const props = defineProps({
    imgUrl: String,
    imgHoverUrl: String,
    text: String,
    selected: Boolean,
});

const hover = ref(false);

const url = computed(() => {
    if(props.selected) return props.imgUrl;
    return hover.value ? props.imgHoverUrl : props.imgUrl; 
})

</script>

<style lang="scss">

</style>