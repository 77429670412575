import { iAppChild } from '@/interfaces/appChildManagement/iAppChild';

const sendMessage = function (children: iAppChild[]| iAppChild, data: any, context: string): void {
    if(children instanceof Array) {
        for(const child of children) {
            const dom: any = document.querySelector(`[data-id="${child.url+child.name}"]`);
            if(dom) {
                dom.contentWindow.postMessage({data, context: context, target: 'all'}, child.url);
            }
        }
    }else {
        const child = children;        
        const dom: any = document.querySelector('[data-id="'+child.url+child.name);
        dom.contentWindow.postMessage({data, context: context, target: 'all'}, child.url);
    }
}

const refreshToken = function (children: iAppChild[]| iAppChild, token: string) {
    sendMessage(children, token, 'refreshToken');
}
const shareUser = function (children: iAppChild[]| iAppChild, user: any) {
    sendMessage(children, user, 'shareUser');
}
const reset = function (children: iAppChild[]| iAppChild) {
    sendMessage(children, null, 'reset');
}



export {
    refreshToken,
    shareUser,
    reset
}