<template>
  <h1>
    {{ capitalizeFirstLetter($t('password')) + ' ' + $t('forgot') }}
  </h1>
  <div class="help-text" style="width: 350px">
    {{ requestSended ? $t('resetPasswordTextSuccess') : $t('resetPasswordHelpText') }}
  </div>
  <div v-if="!requestSended">
    <b-form @submit.prevent="onSubmit">
      <b-form-group :label="capitalizeFirstLetter($t('mail'))" label-for="input-email">
        <b-form-input
          id="input-email"
          v-model="form.login"
          type="email"
          :placeholder="$t('enterYourEmail')"
          required
        ></b-form-input>
        <b-form-invalid-feedback :state="validation.login.state">{{
          validation.login.message
        }}</b-form-invalid-feedback>
      </b-form-group>
      <b-button size="lg" class="w-100" variant="secondary" :style="validation.login.state ? buttonStyle : ''" :disabled="!validation.login.state" @click="onSubmit">
        {{ $t('sendPasswordResetLink') }}
      </b-button>
    </b-form>
    
    <div class="pt-3">
      <a class="text-secondary return-login" href.prevent @click="$emit('on-switch')" v-t="'return'"></a>
    </div>
  </div>
</template>

<script lang="ts">
import { simpleFormValidation } from '@/compositions/formValidation';
import { capitalizeFirstLetter } from '@/helpers/tools';
import { useAuthStore } from '@/store';
import { defineComponent, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    buttonStyle: {
      required: true,
    },
  },
  setup() {
    const authStore = useAuthStore();
    const { t } = useI18n();
    let form = reactive({
      login: '',
    });
    let rules = {
      login: {
        rule: 'required=true|type=email',
        message: t('emailMustBe'),
      },
    };
    let validation = reactive({
      login: {
        state: null,
        message: '',
      },
    });
    watch(form, () => {
      validation.login = simpleFormValidation(rules.login, form.login, t);
    });
    return {
      authStore,
      form,
      validation,
    };
  },
  data() {
    return {
      requestSended: false,
    };
  },
  methods: {
    async onSubmit() {
      try {
        await this.authStore.resetPassword(this.form.login);
        this.requestSended = true;
      } catch (e) {
        console.log(e);
      }
    },
    capitalizeFirstLetter
  },
});
</script>

<style lang="scss">


.return-login {
  text-decoration: underline !important;
  cursor: pointer;
}
</style>
