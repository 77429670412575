import { axiosInstance } from '@/axiosInstance';
import LoginInterface from '@/interfaces/auth/login';
import iUpdatePassword from '@/interfaces/auth/updatePassword';
import router from '@/router';
import axios from 'axios';
import { defineStore } from 'pinia';
import { useApiStore } from './api';
import { useGlobal } from './global';
import Smartlook from 'smartlook-client';

export const useAuthStore = defineStore('useAuthStore', {
    state: () => ({
        isLogged: false as boolean,
        askForNewTokenDate: null as null | Date,
        user: {} as any,
    }),

    getters: {
        defineTokenValidityDate: () => {
            return (validity: number): Date => new Date(Date.now() + validity * 1000 - 1800000);
        },
        userIsMultiSup: (state) => {
            if (!state.isLogged || !state.user.brands) return false;
            const hubService = state.user.brands.global.find((serv: any) => serv.service === 'hub');
            if (!hubService) return false;
            return hubService.rights.role === 1;
        },
    },
    actions: {
        async login(payload: LoginInterface) {
            try {
                const auth = (await axiosInstance.post(`${process.env.VUE_APP_AUTH_URL}/auth/`, payload)).data;
                this.isLogged = true;
                this.setToken(auth.token, this.defineTokenValidityDate(auth.validity));

                if (process.env.VUE_APP_SHOW_SMARTLOOK && JSON.parse(process.env.VUE_APP_SHOW_SMARTLOOK)) {
                    if (process.env.VUE_APP_SMARTLOOK_KEY) {
                        Smartlook.init(process.env.VUE_APP_SMARTLOOK_KEY);
                    }
                }

                router.push('/');
            } catch (e) {
                console.error(e);

                if (axios.isAxiosError(e)) {
                    const status = e.response?.status;
                    if (status) {
                        const error = new Error(`HTTP Error ${status}`);
                        (error as any).statusCode = status;
                        throw error;
                    }
                }
            }
        },

        getTokenDate(): string {
            return localStorage.getItem('askForNewTokenDate') || '';
        },

        getToken(): string {
            return localStorage.getItem('accessToken') || '';
        },

        async fetchUser() {
            this.user = (
                await axiosInstance.get('/users/authenticated/user-info', {
                    headers: { Authorization: `Bearer ${this.getToken()}` },
                })
            ).data.data;
        },

        updatePassword(payload: iUpdatePassword) {
            return axiosInstance.post('/users/updatePassword', payload);
        },

        resetPassword(email: string) {
            return axiosInstance.post('/users/resetPassword', { email });
        },

        async checkTokenValidity() {
            if (this.askForNewTokenDate === null) {
                throw new Error('Token expiration not defined or token expired');
            } else if (this.askForNewTokenDate.getTime() < Date.now()) {
                await this.refreshToken();
            }
            return this.getToken();
        },

        async refreshToken() {
            const response = await axiosInstance.get(`${process.env.VUE_APP_AUTH_URL}/auth/refreshToken`, { headers: { authorization: 'Bearer ' + this.getToken() } });
            if (response.data) {
                this.setToken(response.data.token, this.defineTokenValidityDate(response.data.validity));
            } else {
                throw 'NO TOKEN RECOVER FROM "refreshToken"';
            }
        },

        init(): void {
            const token: string = this.getToken();
            const tokenDate: string = this.getTokenDate();
            if (token && tokenDate) {
                this.setToken(token, new Date(tokenDate));
                this.isLogged = true;
            }
        },

        setToken(token: string, askForNewTokenDate: Date): void {
            localStorage.setItem('accessToken', token);
            localStorage.setItem('askForNewTokenDate', askForNewTokenDate.toString());
            this.askForNewTokenDate = askForNewTokenDate;
        },

        removeToken(): void {
            localStorage.removeItem('accessToken');
        },

        logout(): void {
            const apiStore = useApiStore();
            const globalStore = useGlobal();
            globalStore.customReset();
            apiStore.$reset();
            this.removeToken();
            this.$reset();
            router.push({ name: 'login', params: { brand: globalStore.selectedBrand } });
        },
    },
});
