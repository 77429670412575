import axios from 'axios';
import { createI18n } from 'vue-i18n';

const DEFAULT_APP_LANGUAGE = 'fr';
const LANGUAGES = ['fr', 'en', 'de', 'es', 'it'];

const navLanguage: string = window.navigator.language.split('-')[0] || DEFAULT_APP_LANGUAGE;

const getTranslations = () => axios.get(process.env.VUE_APP_TRANSLATIONS_URL + '?lang=' + navLanguage + '&index.count=100000000');

const i18nModule = async () => {
    const translationsRaw = (await getTranslations()).data;
    const translations: Record<string, Record<string, string>> = {};
    LANGUAGES.forEach((language) => {
        translations[language] = {};
    });
    translationsRaw.forEach((translation: Record<string, string>) => {
        translations[translation.lang][translation.name] = translation.value;
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).tr = translations;

    return createI18n({
        locale: navLanguage,
        legacy: false,
        allowComposition: true,
        messages: translations,
    });
};

export default i18nModule;
