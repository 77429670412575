<template>
  <login-layout>
    <div class="m-auto" style="width: 416px; height: 340px">
      <h1 class="mb-4">{{ $t('updatePasswordTitle') }}</h1>
      <div v-if="passwordReseted">
        <custom-alert :show="true" variant="success">
          {{ $t('updatePasswordSuccessText') }}
        </custom-alert>

        <b-button class="w-100" size="lg" block variant="secondary">
          <a href.prevent="" @click="$router.push('/login')" style="text-decoration: none"
            ><b-spinner v-if="loading" small></b-spinner>{{ $t('meConnect') }}</a
          >
        </b-button>
      </div>
      <b-form v-else @submit.prevent="onSubmit">
        <b-form-group :label="capitalizeFirstLetter($t('newPassword'))" label-for="input-password-1">
          <div class="custom-password-input">
            <input
              id="input-password-1"
              v-model="password1"
              :type="showPassword1 ? 'text' : 'password'"
              :placeholder="$t('newPasswordPlaceholder')"
              :required="true"
            />

            <button type="button" @click="showPassword1 = !showPassword1">
              <img
                v-if="!showPassword1"
                src="@/assets/show-pwd-icon.svg"
                alt="password-show-icon"
                style="width: 12px"
              />
              <img v-else src="@/assets/hide-pwd-icon.svg" alt="password-show-icon" style="width: 12px" />
            </button>
          </div>
        </b-form-group>
        <div class="validation-bar">
          <div v-for="rule in fieldsCount.count" :key="rule" v-bind:class="{ active: rule <= fieldsCount.valid }"></div>
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <pwd-validation-text :valid="ruleCheckLength" :text="$t('passwordLengthCheck')"></pwd-validation-text>
            <pwd-validation-text :valid="ruleCheckUpperCase" :text="$t('passwordUppercaseCheck')"></pwd-validation-text>
          </div>
          <div>
            <pwd-validation-text :valid="ruleCheckNumber" :text="$t('passwordNumberCheck')"></pwd-validation-text>
            <pwd-validation-text
              :valid="ruleCheckSpecialChar"
              :text="$t('passwordSpecialCharCheck')"
            ></pwd-validation-text>
          </div>
        </div>

        <br />
        <b-form-group :label="capitalizeFirstLetter($t('confirmPassword'))" label-for="input-password-2">
          <div class="custom-password-input">
            <input
              id="input-password-2"
              v-model="password2"
              :type="showPassword2 ? 'text' : 'password'"
              :placeholder="$t('confirmPasswordPlaceholder')"
              :required="true"
            />
            <button type="button" @click="showPassword2 = !showPassword2">
              <img
                v-if="!showPassword2"
                src="@/assets/show-pwd-icon.svg"
                alt="password-show-icon"
                style="width: 12px"
              />
              <img v-else src="@/assets/hide-pwd-icon.svg" alt="password-show-icon" style="width: 12px" />
            </button>
            <p class="error-text" v-if="password2NotMatch.state">Les mots de passe ne correspondent pas.</p>
          </div>
        </b-form-group>

        <b-button :disabled="!formValid" variant="secondary" class="w-100 mt-4" size="lg" @click="onSubmit">
          <b-spinner v-if="loading" small></b-spinner>
          Réinitialiser mon mot de passe
        </b-button>
      </b-form>
    </div>
  </login-layout>
</template>

<script lang="ts" setup>
import { capitalizeFirstLetter } from '@/helpers/tools';
import { useAuthStore } from '@/store';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import LoginLayout from '../layout/login-layout.vue';
import PwdValidationText from './pwd-validation-text.vue';

const authStore = useAuthStore();

const password1 = ref('');
const showPassword1 = ref(false);
const password2 = ref('');
const showPassword2 = ref(false);

const passwordReseted = ref(false);
const loading = ref(false);
const { t } = useI18n();
const route = useRoute();

const checkPasswordRegexValidity = (regex: string): boolean => {
  let check = password1.value.match(regex);
  if (check) {
    return check.length > 0;
  }
  return false;
};

const ruleCheckLength = computed(() => {
  return password1.value.length > 11;
});

const ruleCheckUpperCase = computed(() => {
  return checkPasswordRegexValidity('[A-Z]');
});

const ruleCheckNumber = computed(() => {
  return checkPasswordRegexValidity('[0-9]');
});

const ruleCheckSpecialChar = computed(() => {
  return checkPasswordRegexValidity('\\W|_');
});

const fieldsCount = computed(() => {
  const ruleChecks = [
    ruleCheckLength.value,
    ruleCheckUpperCase.value,
    ruleCheckNumber.value,
    ruleCheckSpecialChar.value,
  ];
  return {
    count: 4,
    valid: ruleChecks.filter((ruleCheck) => ruleCheck).length,
  };
});

const password2NotMatch = computed(() => {
  const pass = password2.value;

  if (pass === '') {
    return { state: null, message: '' };
  }
  if (pass === password1.value) {
    return { state: false, message: '' };
  } else {
    return { state: true, message: t('passwordsNotMatching') };
  }
});

const formValid = computed(() => {
  return (
    password2NotMatch.value.state === false &&
    ruleCheckLength.value &&
    ruleCheckUpperCase.value &&
    ruleCheckNumber.value &&
    ruleCheckSpecialChar.value
  );
});

const onSubmit = async () => {
  if (!formValid.value) {
    return;
  }
  loading.value = true;
  try {
    await authStore.updatePassword({
      password: password1.value,
      password_token: route.params.resetMdpToken as string,
    });
    passwordReseted.value = true;
  } catch (e) {
    console.error(e);
  }
  loading.value = false;
};
</script>

<style lang="scss" scoped>
input {
  width: 100%;
  border: none;
  height: 48px;
  padding: 12px 40px 12px 16px;
  border: 1px solid #dbdbdb;
  border-radius: 8px !important;

  &::placeholder {
    font-style: italic;
    color: #707070;
    font-size: 12px;
  }
}
.custom-password-input button {
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.alert.light.alert-success {
  border: none;
  color: #02b15d;
  padding-left: 0;
}

.validation-bar {
  display: flex;
  gap: 4px;
  width: 100%;
  margin-bottom: 1rem;

  div {
    height: 6px;
    background: #dbdbdb;
    width: 100%;
    border-radius: 2px;

    &.active {
      background: #02b15d;
    }
  }
}
.error-text {
  color: #dc3545;
  margin-top: 0.5rem;
}
</style>
