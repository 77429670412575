import { defineStore } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useGlobal } from '@/store/global';

export const useIframeApp = defineStore('useIframeApp', {
    getters: {
        consorUrl() {
            const globalStore = useGlobal();
            return process.env.VUE_APP_FRONT_CONSO_URL + '/' + globalStore.selectedBrand + '?iframe';
        },
        moduleUrl() {
            const globalStore = useGlobal();
            return process.env.VUE_APP_FRONT_MODULE_URL.replace('//', `//${globalStore.selectedBrand}`);
        },
        frontPurchaseUrl() {
            const globalStore = useGlobal();
            return process.env.VUE_APP_FRONT_PURCHASE_URL + '/?iframe&brand=' + globalStore.selectedBrand;
        },
        frontAdminUrl() {
            return process.env.VUE_APP_FRONT_ADMIN_URL + '/?iframe';
        },
        userUrl() {
            return process.env.VUE_APP_FRONT_USER_URL;
        },
        assetUrl() {
            const globalStore = useGlobal();
            return process.env.VUE_APP_FRONT_ASSET + '/?iframe&brand=' + globalStore.selectedBrand;
        },
        importUrl() {
            return process.env.VUE_APP_FRONT_IMPORT_URL;
        },
    },
});
