import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "iframe-container" }
const _hoisted_2 = ["src", "data-id"]

import { reactive } from 'vue';
import { useGlobal } from '../../store';

export default /*@__PURE__*/_defineComponent({
  __name: 'iframeView',
  setup(__props) {

const globalStore = useGlobal();
const appChildren = reactive(globalStore.appChildren);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(appChildren, (child) => {
      return _withDirectives((_openBlock(), _createElementBlock("iframe", {
        key: child.url + child.name,
        src: child.url,
        "data-id": child.url + child.name,
        frameborder: "0",
        ref_for: true,
        ref: "appChildrenDomEl"
      }, null, 8, _hoisted_2)), [
        [_vShow, child.show]
      ])
    }), 128))
  ]))
}
}

})