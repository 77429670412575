import { axiosInstance } from '@/axiosInstance';
import { iAppChild } from '@/interfaces/appChildManagement/iAppChild';
import { availableAppTypes, eLmpV } from '@/interfaces/enums';
import { CustomizationConfig } from '@/types/CustomizationConfig';
import { defineStore } from 'pinia';

export const useGlobal = defineStore('useGlobal', {
    state: () => ({
        appChildren: [] as iAppChild[],
        selectedBrand: '',
        showNavBarre: true,
        selectedLmpV: null as null | eLmpV,
        appStartAtInit: '',
        customizationByBrand: {} as { [brand: string]: CustomizationConfig },
    }),

    getters: {
        appChildrenUrls(): string[] {
            return [
                process.env.VUE_APP_FRONT_HUB_URL,
                process.env.VUE_APP_FRONT_CONSO_URL,
                process.env.VUE_APP_FRONT_USER_URL,
                process.env.VUE_APP_FRONT_PURCHASE_URL,
                process.env.VUE_APP_FRONT_ADMIN_URL,
                process.env.VUE_APP_FRONT_IMPORT_URL,
                process.env.VUE_APP_FRONT_ASSET,
            ];
        },
        getAppChild: (state) => {
            return (name: string, url: string) => state.appChildren.find((child) => child.url === url && child.name === name);
        },
        originInAppChildrenUrls(state) {
            return (origin: string) => {
                if (this.appChildrenUrls.includes(origin)) {
                    return true;
                }
                const formatedUrl: string = process.env.VUE_APP_FRONT_MODULE_URL.replace('//', `//${state.selectedBrand}`);
                return formatedUrl.startsWith(origin);
            };
        },
    },
    actions: {
        customReset() {
            this.appChildren = [];
            this.showNavBarre = true;
        },
        addAppChildren(name: string, url: string, show = false) {
            this.hideAllAppChildren();
            const relatedChild = this.getAppChild(name, url);

            if (relatedChild) {
                relatedChild.show = true;
            } else {
                this.appChildren.push({ name, url, show });
            }
        },
        hideAllAppChildren(): void {
            for (const child of this.appChildren) {
                child.show = false;
            }
        },
        async getBrandConfig(brands: string[], isLogin?: boolean) {
            try {
                const brandsToGet: string[] = brands.filter((brand) => !this.customizationByBrand[brand]);
                if (brandsToGet.length > 0) {
                    Object.assign(this.customizationByBrand, (await axiosInstance.get(`/config/customizations?brands=${brandsToGet}`)).data);
                }
                if (isLogin) {
                    this.selectedBrand = this.customizationByBrand[brands[0]] ? brands[0] : '';
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
});
