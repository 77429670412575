import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore, useApiStore, useGlobal } from '../store';

export const SERVICES = ['purchase', 'lmp', 'conso', 'hub', 'admin', 'assets', 'import'];

export type ServiceStatus = Record<(typeof SERVICES)[number], boolean>;

interface AppType {
    brands: Record<string, ServiceStatus>;
}

// USER RIGHTS
export default function useUserRights() {
    const globalStore = useGlobal();
    const authStore = useAuthStore();
    const apiStore = useApiStore();

    const { brands } = storeToRefs(apiStore);
    const { user, userIsMultiSup } = storeToRefs(authStore);
    const { selectedBrand } = storeToRefs(globalStore);

    const simulatedBrandsCount = ref(0);

    const userHasMultiBrands = computed(() => {
        if (!user?.value?.brands) return false;
        const brands = Object.keys(user.value.brands).filter((brandString: string) => brandString !== 'global');
        return brands.length > 1;
    });

    const userHasLmp = computed(() => {
        if (!user.value || !selectedBrand.value || !user.value.brands) return false;
        return user.value.brands[selectedBrand.value]?.find((brandRight: any) => brandRight.service === 'lmp');
    });
    const userHasConso = computed(() => {
        if (!user.value || !selectedBrand.value || !user.value.brands) return false;
        return user.value.brands[selectedBrand.value]?.find((brandRight: any) => brandRight.service === 'conso');
    });
    const userHasPurchase = computed(() => {
        if (!user.value || !selectedBrand.value || !user.value.brands) return false;

        return user.value.brands[selectedBrand.value]?.find((brandRight: any) => brandRight.service === 'purchase');
    });
    const userHasAsset = computed(() => {
        if (!user.value || !selectedBrand.value || !user.value.brands) return false;

        return user.value.brands[selectedBrand.value]?.find((brandRight: any) => brandRight.service === 'assets');
    });
    const userHasHub = computed(() => {
        if (!user.value || !selectedBrand.value || !user.value.brands) return false;
        return user.value.brands[selectedBrand.value]?.find((brandRight: any) => brandRight.service === 'hub');
    });
    const userHasAdmin = computed(() => {
        if (!user.value?.brands) return false;
        return user.value.brands.global.find((brandRight: any) => brandRight.service === 'admin');
    });
    const userHasImport = computed(() => {
        if (!user.value?.brands) return false;
        return user.value.brands.global.find((brandRight: any) => brandRight.service === 'import');
    });
    const userIsEntityHub = computed(() => {
        if (!user.value?.brands?.global) return false;
        const globalHubService = user.value.brands.global.find((service: { service: string; rights: { role: number } }) => service.service === 'hub');
        return globalHubService?.rights?.role !== 0;
    });

    const userHasMultiServices = computed(() => {
        if (!user.value || !selectedBrand.value || selectedBrand.value === 'global' || !user.value.brands) return false;

        return !userIsEntityHub.value || [userHasConso.value, userHasPurchase.value, userHasAdmin.value, userHasHub.value].filter((bool) => !!bool).length > 1;
    });

    const userHasLmpForBrand = (brand: string) => {
        if (!user.value || !user.value.brands) return false;

        return user.value.brands[brand].find((brandRight: any) => brandRight.service === 'lmp');
    };
    const userHasConsoForBrand = (brand: string) => {
        if (!user.value || !user.value.brands) return false;
        return user.value.brands[brand].find((brandRight: any) => brandRight.service === 'conso');
    };
    const userHasPurchaseForBrand = (brand: string) => {
        if (!user.value || !user.value.brands) return false;
        return user.value.brands[brand].find((brandRight: any) => brandRight.service === 'purchase');
    };
    const userHasAssetsForBrand = (brand: string) => {
        if (!user.value || !user.value.brands) return false;
        return user.value.brands[brand].find((brandRight: any) => brandRight.service === 'assets');
    };
    const userHasHubForBrand = (brand: string) => {
        if (!user.value || !user.value.brands) return false;
        return user.value.brands[brand].find((brandRight: any) => brandRight.service === 'hub');
    };

    const removeRights = (brand: string) => {
        // delete authStore.user.brands[brand];

        if (brands.value.length > 1) {
            const brandIndex = brands.value.findIndex((existingBrand) => existingBrand.brand === brand);

            if (brandIndex !== -1) {
                brands.value.splice(brandIndex, 1);
            }

            selectedBrand.value = brands.value[0].brand;
        } else {
            console.error('CANNOT DELETE EXISTING BRAND ');
        }
    };

    const userHasService = (service: string, brand?: string) => {
        switch (service) {
            case 'lmp':
                return brand ? userHasLmpForBrand(brand) : userHasLmp.value;
            case 'conso':
                return brand ? userHasConsoForBrand(brand) : userHasConso.value;
            case 'purchase':
                return brand ? userHasPurchaseForBrand(brand) : userHasPurchase.value;
            case 'hub':
                return brand ? userHasHubForBrand(brand) : userHasHub.value;
            case 'assets':
                return brand ? userHasAssetsForBrand(brand) : userHasAsset.value;
            case 'admin':
                return userHasAdmin.value;
            case 'import':
                return userHasImport.value;
            default:
                return false;
        }
    };

    const userServices = computed(() => {
        if (user.value?.brands) {
            const availableBrands: string[] = Object.keys(user.value.brands);
            const app: AppType = {
                brands: {},
            };

            availableBrands.forEach((brand) => {
                app.brands[brand] = {} as ServiceStatus;
                SERVICES.forEach((service) => {
                    if (userHasService(service, brand)) {
                        app.brands[brand][service] = true;
                    } else {
                        app.brands[brand][service] = false;
                    }

                    if (userHasService('purchase', brand)) {
                        app.brands[brand]['lmp'] = true;
                    }
                });
            });

            return app;
        }

        return { brands: [] };
    });

    return {
        userIsEntityHub,
        userHasMultiBrands,
        userHasLmp,
        userHasConso,
        userHasPurchase,
        userHasHub,
        userHasAdmin,
        userHasImport,
        userServices,
        userHasMultiServices,
        userHasAsset,
        removeRights,
    };
}
