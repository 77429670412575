import CustomAlert from '@/components/customAlert.vue';
import InputPassword from '@/components/inputPassword.vue';
import { handleChildEvents } from '@/compositions/getMessagesFromChildren';
import fsb from '@adcleek/front-storybook';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { BootstrapVue, FormInputPlugin, NavbarPlugin } from 'bootstrap-vue';
import { createPinia } from 'pinia';
import { createApp, markRaw } from 'vue';
import App from './App.vue';
import i18nModule from './helpers/translations';
import router from './router';
import { useGlobal } from './store';
import './style/index.scss';

library.add(fas, far);

const app = createApp(App);
const pinia = createPinia();

pinia.use(({ store }) => {
    store.router = markRaw(router);
});

app.use(pinia);
app.use(await i18nModule());
app.use(BootstrapVue).use(NavbarPlugin).use(FormInputPlugin);
app.use(router);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('custom-alert', CustomAlert);
app.component('input-password', InputPassword);

fsb.install(app);

app.mount('#app');

const globalStore = useGlobal();
(window as any).app = app;
window.addEventListener(
    'message',
    async (e: any) => {
        if (!globalStore.originInAppChildrenUrls(e.origin)) return;
        handleChildEvents(e.data);
    },
    false
);
