enum eLmpV {
    v1 = 'v1',
    v2 = 'v2',
}

enum availableAppTypes {
    conso = 'CONSO',
    hub = 'HUB',
    lmp = 'MODULE_COMMANDE',
    purchase = 'FRONT_PURCHASE',
    admin = 'FRONT_ADMIN',
    user = 'USER',
    moduleCommande = 'MODULE_COMMANDE',
    frontPurchase = 'FRONT_PURCHASE',
    frontAdmin = 'FRONT_ADMIN',
    frontImport = 'FRONT_IMPORT',
    assets = 'FRONT_ASSET',
}

enum ServicesLabels {
    purchase = 'Commande',
    lmp = 'LMP',
    hub = 'activity',
    admin = 'Admin',
    conso = 'marketingPlan',
    import = 'import',
    assets = 'Assets',
}

export { eLmpV, availableAppTypes, ServicesLabels };
