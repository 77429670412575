<template>
  <div :style="{ color: !valid ? '#B3B3B3' : valid ? '#02B15D' : '#A82E2E' }">
    <font-awesome-icon
      :icon="valid ? 'fa-solid fa-check' : 'fa-solid fa-xmark'"
      :style="{ color: !valid ? '#B3B3B3' : valid ? '#02B15D' : '#A82E2E' }"
    />

    {{ text }}
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    valid: {
      validator: (val) => typeof val === 'boolean' || val === null,
      required: true,
    },
  },

  computed: {
    colorForValidity() {
      switch (this.valid) {
        case true:
          return '#009887';
        case false:
          return '#A82E2E';
        default:
          return '#000';
      }
    },
  },
});
</script>
