import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, unref as _unref, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/show-pwd-icon.svg'
import _imports_1 from '@/assets/hide-pwd-icon.svg'


const _hoisted_1 = {
  class: "m-auto",
  style: {"width":"416px","height":"340px"}
}
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "custom-password-input" }
const _hoisted_5 = ["type", "placeholder"]
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: "password-show-icon",
  style: {"width":"12px"}
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: "password-show-icon",
  style: {"width":"12px"}
}
const _hoisted_8 = { class: "validation-bar" }
const _hoisted_9 = { class: "d-flex justify-content-between" }
const _hoisted_10 = { class: "custom-password-input" }
const _hoisted_11 = ["type", "placeholder"]
const _hoisted_12 = {
  key: 0,
  src: _imports_0,
  alt: "password-show-icon",
  style: {"width":"12px"}
}
const _hoisted_13 = {
  key: 1,
  src: _imports_1,
  alt: "password-show-icon",
  style: {"width":"12px"}
}
const _hoisted_14 = {
  key: 0,
  class: "error-text"
}

import { capitalizeFirstLetter } from '@/helpers/tools';
import { useAuthStore } from '@/store';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import LoginLayout from '../layout/login-layout.vue';
import PwdValidationText from './pwd-validation-text.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index-update-pwd',
  setup(__props) {

const authStore = useAuthStore();

const password1 = ref('');
const showPassword1 = ref(false);
const password2 = ref('');
const showPassword2 = ref(false);

const passwordReseted = ref(false);
const loading = ref(false);
const { t } = useI18n();
const route = useRoute();

const checkPasswordRegexValidity = (regex: string): boolean => {
  let check = password1.value.match(regex);
  if (check) {
    return check.length > 0;
  }
  return false;
};

const ruleCheckLength = computed(() => {
  return password1.value.length > 11;
});

const ruleCheckUpperCase = computed(() => {
  return checkPasswordRegexValidity('[A-Z]');
});

const ruleCheckNumber = computed(() => {
  return checkPasswordRegexValidity('[0-9]');
});

const ruleCheckSpecialChar = computed(() => {
  return checkPasswordRegexValidity('\\W|_');
});

const fieldsCount = computed(() => {
  const ruleChecks = [
    ruleCheckLength.value,
    ruleCheckUpperCase.value,
    ruleCheckNumber.value,
    ruleCheckSpecialChar.value,
  ];
  return {
    count: 4,
    valid: ruleChecks.filter((ruleCheck) => ruleCheck).length,
  };
});

const password2NotMatch = computed(() => {
  const pass = password2.value;

  if (pass === '') {
    return { state: null, message: '' };
  }
  if (pass === password1.value) {
    return { state: false, message: '' };
  } else {
    return { state: true, message: t('passwordsNotMatching') };
  }
});

const formValid = computed(() => {
  return (
    password2NotMatch.value.state === false &&
    ruleCheckLength.value &&
    ruleCheckUpperCase.value &&
    ruleCheckNumber.value &&
    ruleCheckSpecialChar.value
  );
});

const onSubmit = async () => {
  if (!formValid.value) {
    return;
  }
  loading.value = true;
  try {
    await authStore.updatePassword({
      password: password1.value,
      password_token: route.params.resetMdpToken as string,
    });
    passwordReseted.value = true;
  } catch (e) {
    console.error(e);
  }
  loading.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_custom_alert = _resolveComponent("custom-alert")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form_group = _resolveComponent("b-form-group")!
  const _component_b_form = _resolveComponent("b-form")!

  return (_openBlock(), _createBlock(LoginLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('updatePasswordTitle')), 1),
        (passwordReseted.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_custom_alert, {
                show: true,
                variant: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('updatePasswordSuccessText')), 1)
                ], undefined, true),
                _: 1
              }),
              _createVNode(_component_b_button, {
                class: "w-100",
                size: "lg",
                block: "",
                variant: "secondary"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    "href.prevent": "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/login'))),
                    style: {"text-decoration":"none"}
                  }, [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_component_b_spinner, {
                          key: 0,
                          small: ""
                        }))
                      : _createCommentVNode("", true),
                    _createTextVNode(_toDisplayString(_ctx.$t('meConnect')), 1)
                  ])
                ], undefined, true),
                _: 1
              })
            ]))
          : (_openBlock(), _createBlock(_component_b_form, {
              key: 1,
              onSubmit: _withModifiers(onSubmit, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_form_group, {
                  label: _unref(capitalizeFirstLetter)(_ctx.$t('newPassword')),
                  "label-for": "input-password-1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _withDirectives(_createElementVNode("input", {
                        id: "input-password-1",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password1).value = $event)),
                        type: showPassword1.value ? 'text' : 'password',
                        placeholder: _ctx.$t('newPasswordPlaceholder'),
                        required: true
                      }, null, 8, _hoisted_5), [
                        [_vModelDynamic, password1.value]
                      ]),
                      _createElementVNode("button", {
                        type: "button",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (showPassword1.value = !showPassword1.value))
                      }, [
                        (!showPassword1.value)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                          : (_openBlock(), _createElementBlock("img", _hoisted_7))
                      ])
                    ])
                  ], undefined, true),
                  _: 1
                }, 8, ["label"]),
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fieldsCount.value.count, (rule) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: rule,
                      class: _normalizeClass({ active: rule <= fieldsCount.value.valid })
                    }, null, 2))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", null, [
                    _createVNode(PwdValidationText, {
                      valid: ruleCheckLength.value,
                      text: _ctx.$t('passwordLengthCheck')
                    }, null, 8, ["valid", "text"]),
                    _createVNode(PwdValidationText, {
                      valid: ruleCheckUpperCase.value,
                      text: _ctx.$t('passwordUppercaseCheck')
                    }, null, 8, ["valid", "text"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(PwdValidationText, {
                      valid: ruleCheckNumber.value,
                      text: _ctx.$t('passwordNumberCheck')
                    }, null, 8, ["valid", "text"]),
                    _createVNode(PwdValidationText, {
                      valid: ruleCheckSpecialChar.value,
                      text: _ctx.$t('passwordSpecialCharCheck')
                    }, null, 8, ["valid", "text"])
                  ])
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_b_form_group, {
                  label: _unref(capitalizeFirstLetter)(_ctx.$t('confirmPassword')),
                  "label-for": "input-password-2"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      _withDirectives(_createElementVNode("input", {
                        id: "input-password-2",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((password2).value = $event)),
                        type: showPassword2.value ? 'text' : 'password',
                        placeholder: _ctx.$t('confirmPasswordPlaceholder'),
                        required: true
                      }, null, 8, _hoisted_11), [
                        [_vModelDynamic, password2.value]
                      ]),
                      _createElementVNode("button", {
                        type: "button",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (showPassword2.value = !showPassword2.value))
                      }, [
                        (!showPassword2.value)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                          : (_openBlock(), _createElementBlock("img", _hoisted_13))
                      ]),
                      (password2NotMatch.value.state)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_14, "Les mots de passe ne correspondent pas."))
                        : _createCommentVNode("", true)
                    ])
                  ], undefined, true),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_b_button, {
                  disabled: !formValid.value,
                  variant: "secondary",
                  class: "w-100 mt-4",
                  size: "lg",
                  onClick: onSubmit
                }, {
                  default: _withCtx(() => [
                    (loading.value)
                      ? (_openBlock(), _createBlock(_component_b_spinner, {
                          key: 0,
                          small: ""
                        }))
                      : _createCommentVNode("", true),
                    _cache[5] || (_cache[5] = _createTextVNode(" Réinitialiser mon mot de passe "))
                  ], undefined, true),
                  _: 1
                }, 8, ["disabled"])
              ], undefined, true),
              _: 1
            }))
      ])
    ], undefined, true),
    _: 1
  }))
}
}

})