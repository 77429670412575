<template>
     <b-alert :show="show" :variant="variant" class="light">
         <font-awesome-icon :icon="icon" style="margin-right: 5px;"/>
        <slot></slot>
    </b-alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        show: {
            required: true,
            type: Boolean,
        },
        variant: {
            required: true,
            type: String,
        }
    },

    computed: {
        icon () {
            switch(this.variant) {
                case 'danger':
                    return 'fa-circle-exclamation';
                case 'success':
                    return 'fa-circle-check';
                default:
                    return '';
            }
        }
    }
});
</script>
