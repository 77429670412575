<template>
    <base-modal
        :modal-show="showModal"
        :title="t('modalSupportTitle')"
        :primary-label="t('send')"
        :on-primary-button-click="sendMessage"
        :is-primary-button-disabled="disableSendButton || loadingSendMessage"
        :load-primary-button-spinner="loadingSendMessage"
        :show-secondary-button="false"
        :tertiary-label="t('cancel')"
        :on-tertiary-button-click="closeModal"
        @close-modal="closeModal"
    >
        <div class="support-form">
            <p class="fsb-typo-para-regular">{{ t('modalSupportHelp') }}</p>
            <simple-input :label="t('modalSupportSubject')" :required="true" @update:value="updateSubject"></simple-input>
            <fsb-textarea v-model:value="formData.message" :label="t('message')" required />
            <fsb-upload-file @update:file-list="(fileList: File[]) => (formData.files = fileList)" />
        </div>
    </base-modal>
</template>

<script lang="ts" setup>
import { useApiStore } from '@/store';
import { computed, defineEmits, defineProps, inject, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps<{
    showModal: boolean;
}>();

const emit = defineEmits(['update:showModal']);

const { t } = useI18n({ useScope: 'global' });
const snackBar: { open(variant: string, text: string, delay?: number): void } | undefined = inject('snackBar');

const apiStore = useApiStore();

const formData = reactive({ subject: '', message: '', files: [] as File[] });

const loadingSendMessage = ref(false);

const disableSendButton = computed(() => formData.subject.length === 0 || formData.message.length === 0);

const updateSubject = ({ value }: { value: string }) => {
    formData.subject = value;
};

const sendMessage = async () => {
    if (!disableSendButton.value) {
        const formDataToSend = new FormData();
        formDataToSend.append('subject', formData.subject);
        formDataToSend.append('textMail', formData.message);
        formData.files.forEach((file, index) => formDataToSend.append(`files[${index}]`, file));
        try {
            loadingSendMessage.value = true;
            await apiStore.sendSupportMessage(formDataToSend);
            closeModal();
            snackBar?.open('success', t('messageSentSuccessfully'));
        } catch (error) {
            console.log(error);
        } finally {
            loadingSendMessage.value = false;
        }
    }
};

const closeModal = () => {
    emit('update:showModal', false);
    Object.assign(formData, { subject: '', message: '', files: [] });
};
</script>

<style lang="scss" scoped>
.support-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    :deep(.form-control) {
        color: var(--grayscale-black);
    }

    :deep(p) {
        margin-bottom: 0px;
    }

    > p {
        color: var(--grayscale-gray-dark);
    }
}
</style>
