import { refreshToken, shareUser } from '@/compositions/sendMessagesToChildren';
import { availableAppTypes, eLmpV } from '@/interfaces/enums';
import { useAuthStore, useGlobal, useIframeApp } from '../store';

type childEventData = {
    request: string
}

const handleChildEvents = (data: childEventData) => {
    const request = data.request;
    switch(request) {
        case 'refreshToken':
            onRefreshToken();
            break;
        case 'hideNavbar':
            hideNavbar();
            break;
        case 'showNavbar':
            showNavbar();
            break;
        case 'oldLmp':
            oldLmp();
            break;
        case 'switchFrontPurchase':
            frontPurchase();
            break;
        default:
    }
}

const onRefreshToken = async () => {
    const authStore = useAuthStore();
    try{
        const globalStore = useGlobal();
        const token = await authStore.checkTokenValidity();
        const user = authStore.user;
        
        refreshToken(globalStore.appChildren, token as string);
        shareUser(globalStore.appChildren, JSON.stringify(user));
    } catch (e: any) {
        console.error(e);
        if (e?.response?.status === 401) {
            authStore.logout();
        }
    }
}
const hideNavbar = () => {
    const globalStore = useGlobal();
    globalStore.showNavBarre = false;
}
const showNavbar = () => {
    const globalStore = useGlobal();
    globalStore.showNavBarre = true;
}
const oldLmp = () => {
    const globalStore = useGlobal();
    const iframeApp = useIframeApp();
    globalStore.addAppChildren(availableAppTypes.moduleCommande, iframeApp.moduleUrl, true)
    globalStore.selectedLmpV = eLmpV.v1;
}
const frontPurchase = () => {
    const globalStore = useGlobal();
    const iframeApp = useIframeApp();
    globalStore.addAppChildren(availableAppTypes.frontPurchase, iframeApp.frontPurchaseUrl, true)
    globalStore.selectedLmpV = eLmpV.v2;
}

export { handleChildEvents };
