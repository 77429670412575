import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_alert = _resolveComponent("b-alert")!

  return (_openBlock(), _createBlock(_component_b_alert, {
    show: _ctx.show,
    variant: _ctx.variant,
    class: "light"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_font_awesome_icon, {
        icon: _ctx.icon,
        style: {"margin-right":"5px"}
      }, null, 8, ["icon"]),
      _renderSlot(_ctx.$slots, "default")
    ], undefined, true),
    _: 3
  }, 8, ["show", "variant"]))
}