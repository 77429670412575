import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }

import { availableAppTypes, ServicesLabels } from '@/interfaces/enums';

import { storeToRefs } from 'pinia';
import { Ref, computed, h, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import SupportModal from './components/Support/SupportModal.vue';
import { useApiStore, useAuthStore, useGlobal, useIframeApp } from './store';
import dropdownItem from './views/dropdownItem.vue';
import useUserRights from './helpers/userRights';

import useUserPane from './helpers/userPane';
import { onMounted } from 'vue';
import { onUnmounted } from 'vue';
import { nextTick } from 'vue';

interface iDropdownFactory {
    label: string;
    value: string;
    clickArg: string | null;
    imgUrl: string;
    imgHoverUrl: string;
    text: string;
    isEntity?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { t } = useI18n({ useScope: 'global' });

const apiStore = useApiStore();
const globalStore = useGlobal();
const authStore = useAuthStore();
const iframeApp = useIframeApp();

const { user, isLogged, userIsMultiSup } = storeToRefs(authStore);
const { consorUrl, moduleUrl, frontPurchaseUrl, frontAdminUrl, userUrl, assetUrl, importUrl } = storeToRefs(iframeApp);
const { customizationByBrand, selectedBrand, showNavBarre } = storeToRefs(globalStore);
const { brands } = storeToRefs(apiStore);

const hubUrl: string = process.env.VUE_APP_FRONT_HUB_URL;
const ressourceUrl: string = process.env.VUE_APP_BRAND_LOGO_URL;
const entityLogoUrl: string = process.env.VUE_APP_ENTITY_URL_LOGO;

const showSupportModal = ref(false);

const displayNavBar = computed(() => {
    if (authStore.isLogged) {
        const brandColor = customizationByBrand.value[selectedBrand.value]?.color;
        return brandColor ? { borderBottom: `2px solid ${brandColor}` } : {};
    } else {
        return { padding: 0 };
    }
});

const dropdownEntity = ref();
const dropdownUserAction = ref();

const closeDropdownOnStart = () => {
    handleDropdownEntity(false);
    handleDropdownUserAction(false);
    setSelectedDropdownEntity();
};

const displayDropdownEntity = computed(() => {
    return dropdownEntity?.value?.displayDropdown;
});

const handleDropdownEntity = (open?: boolean) => {
    if (typeof open === 'boolean') {
        if (open) dropdownEntity.value?.openDropdown();
        else dropdownEntity.value?.closeDropdown();
    } else {
        if (displayDropdownEntity.value) {
            dropdownEntity.value?.closeDropdown();
        } else {
            dropdownEntity.value?.openDropdown();
        }
    }
};
const handleDropdownUserAction = (open = true) => {
    if (open) dropdownUserAction.value.openDropdown();
    else dropdownUserAction.value.closeDropdown();
};

let selectedDropdownItem: Ref<any> = ref([]);
let currentApp = ref(availableAppTypes.conso);

const switchRelatedHub = () => {
    if (selectedBrand.value) addAppChildren('hub', hubUrl + '?hub_brand=' + selectedBrand.value);
    else addAppChildren('hub', hubUrl);
};

const addAppChildren = async (name: string, url: string) => {
    currentApp.value = (availableAppTypes as any)[name];
    globalStore.addAppChildren(name, url, true);
    globalStore.appStartAtInit = name;
};

// USER RIGHTS
const { userHasConso, userHasPurchase, userHasHub, userHasAdmin, userServices, userHasMultiServices, userHasAsset, userHasImport } = useUserRights();

const switchApp = (service: string) => {
    switch (service) {
        case 'import':
            addAppChildren('import', importUrl.value);
            return;
        case 'lmp':
            // addAppChildren('moduleCommande', moduleUrl.value);
            return;
        case 'conso':
            addAppChildren('conso', consorUrl.value);
            return;
        case 'purchase':
            addAppChildren('frontPurchase', frontPurchaseUrl.value);
            return;
        case 'hub':
            switchRelatedHub();
            return;
        case 'admin':
            addAppChildren('frontAdmin', frontAdminUrl.value);
            return;
        case 'assets':
            addAppChildren('assets', assetUrl.value);
            return;
        default:
            console.error('NOT ONCE OF THIS APPLICATION ASSIGNED TO USER: [HUB, MODULE_COMMANDE, CONSO]');
            return;
    }
};

const setSelectedDropdown = (targetCode: string, isEntity = false) => {
    let target = listEntityDropdown.value.find((dr) => dr.value === targetCode);
    selectedDropdownItem.value[0] = target;

    if (isEntity) {
        selectedBrand.value = 'global';
    }
};

const canAccessGlobalHub = (selectedBrand: any, entityCode: string, user: any): boolean => {
    const isGlobalBrand = selectedBrand.value === 'global' || !selectedBrand.value;
    const hasEntityCode = Boolean(entityCode);
    const hasGlobalHubAccess = user?.value?.brands?.global.some((brandRight: any) => brandRight.service === 'hub');

    return isGlobalBrand && hasEntityCode && hasGlobalHubAccess;
};

const startFirstAvailableModuleFound = () => {
    const entityCode: any = user.value.entity?.code;
    setDropdownImage();

    // means entityhub

    if (canAccessGlobalHub(selectedBrand, entityCode, user)) {
        setSelectedDropdown(entityCode, true);
        selectedBrand.value = 'global';

        return addAppChildren('hub', hubUrl);
    } else if (!selectedBrand.value) {
        selectedDropdownItem.value[0] = listEntityDropdown.value[0];
        selectedBrand.value = brands.value[0]?.brand;
    } else if (currentApp.value) {
        if (userHasPurchase.value) {
            switchApp('purchase');
        } else if (userHasConso.value) {
            switchApp('conso');
        } else if (userHasHub.value) {
            switchRelatedHub();
        } else if (userHasAdmin.value) {
            switchApp('admin');
        } else if (userHasImport.value) {
            switchApp('import');
        } else if (userHasAsset.value) {
            switchApp('assets');
        } else {
            console.error('NOT ONCE OF THIS APPLICATION ASSIGNED TO USER: [HUB, MODULE_COMMANDE, CONSO]');
        }
    }

    setSelectedDropdownEntity();
};

watch(
    () => brands.value,
    async (newValue) => {
        if (isLogged.value) {
            if (Object.keys(user.value).length === 0) {
                await authStore.fetchUser().catch(console.log);
            }
            if (newValue.length) {
                startFirstAvailableModuleFound();
            }
        }
    }
);

const userName = computed(() => {
    const { firstname, lastname } = user.value;
    if (!firstname || !lastname) return '';
    return `${firstname[0]}.${lastname}`;
});

const addBrandAppChildren = async (brand: string | null) => {
    if (selectedBrand.value === brand) return;
    selectedBrand.value = brand || '';
    startFirstAvailableModuleFound();
};

const brandImgStyle = ref({ width: 100, height: 32 });
const brandEntityImg = ref('');

const setSelectedDropdownEntity = () => {
    let entity = null;

    if (!selectedBrand.value) entity = listEntityDropdown.value[0];
    else {
        entity = listEntityDropdown.value.find((ent) => ent.value === selectedBrand.value);
    }

    if (userServices.value?.brands && Object.keys(userServices.value.brands).length === 1 && Object.keys(userServices.value.brands).includes('global')) {
        selectedBrand.value = 'global';
    }

    dropdownEntity.value?.setSelectedItems(entity);
};

const setDropdownImage = () => {
    let image = new Image();
    image.onload = function () {
        brandImgStyle.value.width = image.width;
        brandImgStyle.value.height = image.height;
    };
    if (selectedBrand.value && selectedBrand.value !== 'global') {
        image.src = ressourceUrl + selectedBrand.value + '--fullsize.svg';
    } else {
        image.src = entityLogoUrl + user.value.entity_code + '--fullsize.svg';
    }
    brandEntityImg.value = image.src;
};

watch(
    () => selectedBrand.value,
    () => {
        document.title = customizationByBrand.value[selectedBrand.value]?.tabTitle || 'adcleek';
        setDropdownImage();
    }
);

const dropdownItemFactory = (val: iDropdownFactory) => {
    let selected = selectedDropdownItem.value[0]?.value === val.value;
    return {
        isEntity: val.isEntity ?? false,
        label: val.label,
        value: val.value,
        onClick() {
            if (selected) return;
            handleDropdownEntity();
            addBrandAppChildren(val.clickArg);
        },
        content: h(dropdownItem, {
            imgUrl: val.imgUrl,
            text: val.text,
            imgHoverUrl: val.imgHoverUrl,
            selected,
        }),
    };
};

const listEntityDropdown = computed(() => {
    if (!user.value) return [];
    let list: any[] = [];
    if (user.value && user.value.entity_code) {
        list.push(
            dropdownItemFactory({
                label: user.value?.entity?.name,
                value: user.value?.entity?.name,
                clickArg: null,
                imgUrl: entityLogoUrl + user.value.entity_code + '.svg',
                imgHoverUrl: entityLogoUrl + user.value.entity_code + '.svg',
                text: user.value?.entity?.name,
                isEntity: true,
            })
        );
    }
    for (let br of brands.value) {
        let imgUrl: string = ressourceUrl + br.brand;
        if (selectedDropdownItem.value[0]?.value === br.brand) {
            imgUrl += '_logo--white';
        }
        imgUrl += '.svg';

        list.push(
            dropdownItemFactory({
                label: br.plateformName,
                value: br.brand,
                clickArg: br.brand,
                imgUrl: imgUrl,
                imgHoverUrl: ressourceUrl + br.brand + '.svg',
                text: br.plateformName,
            })
        );
    }

    return list;
});

const listUserAction = computed(() => {
    return [
        {
            label: 'userName',
            value: 'userName',
            onClick() {
                addAppChildren('frontUser', userUrl.value);
                handleDropdownUserAction(false);
            },
            content: h(dropdownItem, {
                imgUrl: '',
                text: t('myAccount'),
                imgHoverUrl: '',
            }),
        },
        {
            label: 'logout',
            value: 'logout',
            onClick() {
                authStore.logout();
            },
            content: h(dropdownItem, {
                imgUrl: '',
                text: t('logout'),
                imgHoverUrl: '',
            }),
        },
    ];
});

const { paneRights } = useUserPane({ startFirstAvailableModuleFound });

watch(
    () => dropdownEntity.value,
    () => {
        if (dropdownEntity.value) {
            closeDropdownOnStart();
        }
    },
    { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_ad_icon = _resolveComponent("ad-icon")!
  const _component_fsb_dropdown = _resolveComponent("fsb-dropdown")!
  const _component_b_nav_item = _resolveComponent("b-nav-item")!
  const _component_b_navbar_nav = _resolveComponent("b-navbar-nav")!
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_b_navbar = _resolveComponent("b-navbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(isLogged) && _unref(showNavBarre))
      ? (_openBlock(), _createBlock(_component_b_navbar, {
          key: 0,
          toggleable: "lg",
          type: "light",
          class: "pr-5 hub-navbar",
          style: _normalizeStyle(displayNavBar.value)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              (_unref(paneRights) && _unref(selectedBrand) && _unref(paneRights)[_unref(selectedBrand)])
                ? (_openBlock(), _createBlock(_component_b_navbar_nav, { key: 0 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        style: _normalizeStyle(brandImgStyle.value)
                      }, [
                        _createVNode(_component_fsb_dropdown, {
                          ref_key: "dropdownEntity",
                          ref: dropdownEntity,
                          modelValue: _unref(selectedDropdownItem),
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(selectedDropdownItem) ? (selectedDropdownItem).value = $event : selectedDropdownItem = $event)),
                          list: listEntityDropdown.value.length > 1 ? listEntityDropdown.value.filter((entity) => !entity.isEntity) : [],
                          offset: { top: 10 },
                          "full-width": "",
                          "select-one": "",
                          "close-dropdown-on-item-click": "",
                          "close-dropdown-on-click-outside": true,
                          "bind-min-width": 208
                        }, {
                          label: _withCtx(() => [
                            _createElementVNode("div", {
                              class: "dropdown-entity-input",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (handleDropdownEntity()))
                            }, [
                              (_unref(userIsMultiSup))
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    height: "32",
                                    src: brandEntityImg.value,
                                    alt: ""
                                  }, null, 8, _hoisted_1))
                                : (_openBlock(), _createElementBlock("img", {
                                    key: 1,
                                    style: {"cursor":"pointer","margin-left":"10px"},
                                    src: brandEntityImg.value,
                                    alt: ""
                                  }, null, 8, _hoisted_2)),
                              (listEntityDropdown.value.length > 1)
                                ? (_openBlock(), _createBlock(_component_ad_icon, {
                                    key: 2,
                                    class: "ml-1",
                                    icon: displayDropdownEntity.value ? 'chevron-up' : 'chevron-down'
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue", "list"])
                      ], 4),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_unref(paneRights)[_unref(selectedBrand)]), (service) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `selector-service-${service[0]}`,
                          class: _normalizeClass(["app-nav-item d-flex align-items-center", { 'ml-3': service[1] && service[0] !== 'lmp' }])
                        }, [
                          (service[1] && service[0] !== 'lmp')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                (service[0] !== 'hub' ? _unref(selectedBrand) !== 'global' : true)
                                  ? (_openBlock(), _createBlock(_component_b_nav_item, {
                                      key: 0,
                                      class: _normalizeClass({ active: _unref(currentApp) === _unref(availableAppTypes)[service[0]] }),
                                      onClick: () => switchApp(service[0])
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t(_unref(ServicesLabels)[service[0]])), 1)
                                      ], undefined, true),
                                      _: 2
                                    }, 1032, ["class", "onClick"]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ], 2))
                      }), 128))
                    ], undefined, true),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_b_navbar_nav, { class: "ml-auto right-nav" }, {
              default: _withCtx(() => [
                _createVNode(_component_ui_button, {
                  class: "mr-2",
                  "left-icon": "support",
                  label: _unref(t)('needHelp'),
                  variant: "white",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (showSupportModal.value = true))
                }, null, 8, ["label"]),
                _createVNode(_component_fsb_dropdown, {
                  ref_key: "dropdownUserAction",
                  ref: dropdownUserAction,
                  list: listUserAction.value,
                  "no-select": "",
                  "linked-input": userName.value,
                  "close-dropdown-on-click-outside": ""
                }, null, 8, ["list", "linked-input"])
              ], undefined, true),
              _: 1
            })
          ], undefined, true),
          _: 1
        }, 8, ["style"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(SupportModal, {
      "show-modal": showSupportModal.value,
      "onUpdate:showModal": _cache[3] || (_cache[3] = ($event: any) => ((showSupportModal).value = $event))
    }, null, 8, ["show-modal"])
  ], 64))
}
}

})