<template>
    <b-navbar v-if="isLogged && showNavBarre" toggleable="lg" type="light" class="pr-5 hub-navbar" :style="displayNavBar">
        <!-- DISPLAYED Dropdown -->
        <div>
            <b-navbar-nav v-if="paneRights && selectedBrand && paneRights[selectedBrand]">
                <div :style="brandImgStyle">
                    <fsb-dropdown
                        ref="dropdownEntity"
                        v-model="selectedDropdownItem"
                        :list="listEntityDropdown.length > 1 ? listEntityDropdown.filter((entity) => !entity.isEntity) : []"
                        :offset="{ top: 10 }"
                        full-width
                        select-one
                        close-dropdown-on-item-click
                        :close-dropdown-on-click-outside="true"
                        :bind-min-width="208"
                    >
                        <template #label>
                            <div class="dropdown-entity-input" @click="handleDropdownEntity()">
                                <img v-if="userIsMultiSup" height="32" :src="brandEntityImg" alt="" />
                                <img v-else style="cursor: pointer; margin-left: 10px" :src="brandEntityImg" alt="" />
                                <ad-icon v-if="listEntityDropdown.length > 1" class="ml-1" :icon="displayDropdownEntity ? 'chevron-up' : 'chevron-down'"></ad-icon>
                            </div>
                        </template>
                    </fsb-dropdown>
                </div>

                <!-- DISPLAYED SERVICES -->

                <div
                    v-for="service in Object.entries(paneRights[selectedBrand])"
                    :key="`selector-service-${service[0]}`"
                    class="app-nav-item d-flex align-items-center"
                    :class="{ 'ml-3': service[1] && service[0] !== 'lmp' }"
                >
                    <div v-if="service[1] && service[0] !== 'lmp'">
                        <b-nav-item
                            v-if="service[0] !== 'hub' ? selectedBrand !== 'global' : true"
                            :class="{ active: currentApp === availableAppTypes[service[0]] }"
                            @click="() => switchApp(service[0])"
                        >
                            {{ $t(ServicesLabels[service[0]]) }}
                        </b-nav-item>
                    </div>
                </div>
            </b-navbar-nav>
        </div>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto right-nav">
            <ui-button class="mr-2" left-icon="support" :label="t('needHelp')" variant="white" @click="showSupportModal = true" />
            <fsb-dropdown ref="dropdownUserAction" :list="listUserAction" no-select :linked-input="userName" close-dropdown-on-click-outside />
        </b-navbar-nav>
    </b-navbar>
    <router-view />
    <support-modal v-model:show-modal="showSupportModal"></support-modal>
</template>

<script lang="ts" setup>
import { availableAppTypes, ServicesLabels } from '@/interfaces/enums';

import { storeToRefs } from 'pinia';
import { Ref, computed, h, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import SupportModal from './components/Support/SupportModal.vue';
import { useApiStore, useAuthStore, useGlobal, useIframeApp } from './store';
import dropdownItem from './views/dropdownItem.vue';
import useUserRights from './helpers/userRights';

import useUserPane from './helpers/userPane';
import { onMounted } from 'vue';
import { onUnmounted } from 'vue';
import { nextTick } from 'vue';

const { t } = useI18n({ useScope: 'global' });

const apiStore = useApiStore();
const globalStore = useGlobal();
const authStore = useAuthStore();
const iframeApp = useIframeApp();

const { user, isLogged, userIsMultiSup } = storeToRefs(authStore);
const { consorUrl, moduleUrl, frontPurchaseUrl, frontAdminUrl, userUrl, assetUrl, importUrl } = storeToRefs(iframeApp);
const { customizationByBrand, selectedBrand, showNavBarre } = storeToRefs(globalStore);
const { brands } = storeToRefs(apiStore);

const hubUrl: string = process.env.VUE_APP_FRONT_HUB_URL;
const ressourceUrl: string = process.env.VUE_APP_BRAND_LOGO_URL;
const entityLogoUrl: string = process.env.VUE_APP_ENTITY_URL_LOGO;

const showSupportModal = ref(false);

const displayNavBar = computed(() => {
    if (authStore.isLogged) {
        const brandColor = customizationByBrand.value[selectedBrand.value]?.color;
        return brandColor ? { borderBottom: `2px solid ${brandColor}` } : {};
    } else {
        return { padding: 0 };
    }
});

const dropdownEntity = ref();
const dropdownUserAction = ref();

const closeDropdownOnStart = () => {
    handleDropdownEntity(false);
    handleDropdownUserAction(false);
    setSelectedDropdownEntity();
};

const displayDropdownEntity = computed(() => {
    return dropdownEntity?.value?.displayDropdown;
});

const handleDropdownEntity = (open?: boolean) => {
    if (typeof open === 'boolean') {
        if (open) dropdownEntity.value?.openDropdown();
        else dropdownEntity.value?.closeDropdown();
    } else {
        if (displayDropdownEntity.value) {
            dropdownEntity.value?.closeDropdown();
        } else {
            dropdownEntity.value?.openDropdown();
        }
    }
};
const handleDropdownUserAction = (open = true) => {
    if (open) dropdownUserAction.value.openDropdown();
    else dropdownUserAction.value.closeDropdown();
};

let selectedDropdownItem: Ref<any> = ref([]);
let currentApp = ref(availableAppTypes.conso);

const switchRelatedHub = () => {
    if (selectedBrand.value) addAppChildren('hub', hubUrl + '?hub_brand=' + selectedBrand.value);
    else addAppChildren('hub', hubUrl);
};

const addAppChildren = async (name: string, url: string) => {
    currentApp.value = (availableAppTypes as any)[name];
    globalStore.addAppChildren(name, url, true);
    globalStore.appStartAtInit = name;
};

// USER RIGHTS
const { userHasConso, userHasPurchase, userHasHub, userHasAdmin, userServices, userHasMultiServices, userHasAsset, userHasImport } = useUserRights();

const switchApp = (service: string) => {
    switch (service) {
        case 'import':
            addAppChildren('import', importUrl.value);
            return;
        case 'lmp':
            // addAppChildren('moduleCommande', moduleUrl.value);
            return;
        case 'conso':
            addAppChildren('conso', consorUrl.value);
            return;
        case 'purchase':
            addAppChildren('frontPurchase', frontPurchaseUrl.value);
            return;
        case 'hub':
            switchRelatedHub();
            return;
        case 'admin':
            addAppChildren('frontAdmin', frontAdminUrl.value);
            return;
        case 'assets':
            addAppChildren('assets', assetUrl.value);
            return;
        default:
            console.error('NOT ONCE OF THIS APPLICATION ASSIGNED TO USER: [HUB, MODULE_COMMANDE, CONSO]');
            return;
    }
};

const setSelectedDropdown = (targetCode: string, isEntity = false) => {
    let target = listEntityDropdown.value.find((dr) => dr.value === targetCode);
    selectedDropdownItem.value[0] = target;

    if (isEntity) {
        selectedBrand.value = 'global';
    }
};

const canAccessGlobalHub = (selectedBrand: any, entityCode: string, user: any): boolean => {
    const isGlobalBrand = selectedBrand.value === 'global' || !selectedBrand.value;
    const hasEntityCode = Boolean(entityCode);
    const hasGlobalHubAccess = user?.value?.brands?.global.some((brandRight: any) => brandRight.service === 'hub');

    return isGlobalBrand && hasEntityCode && hasGlobalHubAccess;
};

const startFirstAvailableModuleFound = () => {
    const entityCode: any = user.value.entity?.code;
    setDropdownImage();

    // means entityhub

    if (canAccessGlobalHub(selectedBrand, entityCode, user)) {
        setSelectedDropdown(entityCode, true);
        selectedBrand.value = 'global';

        return addAppChildren('hub', hubUrl);
    } else if (!selectedBrand.value) {
        selectedDropdownItem.value[0] = listEntityDropdown.value[0];
        selectedBrand.value = brands.value[0]?.brand;
    } else if (currentApp.value) {
        if (userHasPurchase.value) {
            switchApp('purchase');
        } else if (userHasConso.value) {
            switchApp('conso');
        } else if (userHasHub.value) {
            switchRelatedHub();
        } else if (userHasAdmin.value) {
            switchApp('admin');
        } else if (userHasImport.value) {
            switchApp('import');
        } else if (userHasAsset.value) {
            switchApp('assets');
        } else {
            console.error('NOT ONCE OF THIS APPLICATION ASSIGNED TO USER: [HUB, MODULE_COMMANDE, CONSO]');
        }
    }

    setSelectedDropdownEntity();
};

watch(
    () => brands.value,
    async (newValue) => {
        if (isLogged.value) {
            if (Object.keys(user.value).length === 0) {
                await authStore.fetchUser().catch(console.log);
            }
            if (newValue.length) {
                startFirstAvailableModuleFound();
            }
        }
    }
);

const userName = computed(() => {
    const { firstname, lastname } = user.value;
    if (!firstname || !lastname) return '';
    return `${firstname[0]}.${lastname}`;
});

const addBrandAppChildren = async (brand: string | null) => {
    if (selectedBrand.value === brand) return;
    selectedBrand.value = brand || '';
    startFirstAvailableModuleFound();
};

const brandImgStyle = ref({ width: 100, height: 32 });
const brandEntityImg = ref('');

const setSelectedDropdownEntity = () => {
    let entity = null;

    if (!selectedBrand.value) entity = listEntityDropdown.value[0];
    else {
        entity = listEntityDropdown.value.find((ent) => ent.value === selectedBrand.value);
    }

    if (userServices.value?.brands && Object.keys(userServices.value.brands).length === 1 && Object.keys(userServices.value.brands).includes('global')) {
        selectedBrand.value = 'global';
    }

    dropdownEntity.value?.setSelectedItems(entity);
};

const setDropdownImage = () => {
    let image = new Image();
    image.onload = function () {
        brandImgStyle.value.width = image.width;
        brandImgStyle.value.height = image.height;
    };
    if (selectedBrand.value && selectedBrand.value !== 'global') {
        image.src = ressourceUrl + selectedBrand.value + '--fullsize.svg';
    } else {
        image.src = entityLogoUrl + user.value.entity_code + '--fullsize.svg';
    }
    brandEntityImg.value = image.src;
};

watch(
    () => selectedBrand.value,
    () => {
        document.title = customizationByBrand.value[selectedBrand.value]?.tabTitle || 'adcleek';
        setDropdownImage();
    }
);

interface iDropdownFactory {
    label: string;
    value: string;
    clickArg: string | null;
    imgUrl: string;
    imgHoverUrl: string;
    text: string;
    isEntity?: boolean;
}
const dropdownItemFactory = (val: iDropdownFactory) => {
    let selected = selectedDropdownItem.value[0]?.value === val.value;
    return {
        isEntity: val.isEntity ?? false,
        label: val.label,
        value: val.value,
        onClick() {
            if (selected) return;
            handleDropdownEntity();
            addBrandAppChildren(val.clickArg);
        },
        content: h(dropdownItem, {
            imgUrl: val.imgUrl,
            text: val.text,
            imgHoverUrl: val.imgHoverUrl,
            selected,
        }),
    };
};

const listEntityDropdown = computed(() => {
    if (!user.value) return [];
    let list: any[] = [];
    if (user.value && user.value.entity_code) {
        list.push(
            dropdownItemFactory({
                label: user.value?.entity?.name,
                value: user.value?.entity?.name,
                clickArg: null,
                imgUrl: entityLogoUrl + user.value.entity_code + '.svg',
                imgHoverUrl: entityLogoUrl + user.value.entity_code + '.svg',
                text: user.value?.entity?.name,
                isEntity: true,
            })
        );
    }
    for (let br of brands.value) {
        let imgUrl: string = ressourceUrl + br.brand;
        if (selectedDropdownItem.value[0]?.value === br.brand) {
            imgUrl += '_logo--white';
        }
        imgUrl += '.svg';

        list.push(
            dropdownItemFactory({
                label: br.plateformName,
                value: br.brand,
                clickArg: br.brand,
                imgUrl: imgUrl,
                imgHoverUrl: ressourceUrl + br.brand + '.svg',
                text: br.plateformName,
            })
        );
    }

    return list;
});

const listUserAction = computed(() => {
    return [
        {
            label: 'userName',
            value: 'userName',
            onClick() {
                addAppChildren('frontUser', userUrl.value);
                handleDropdownUserAction(false);
            },
            content: h(dropdownItem, {
                imgUrl: '',
                text: t('myAccount'),
                imgHoverUrl: '',
            }),
        },
        {
            label: 'logout',
            value: 'logout',
            onClick() {
                authStore.logout();
            },
            content: h(dropdownItem, {
                imgUrl: '',
                text: t('logout'),
                imgHoverUrl: '',
            }),
        },
    ];
});

const { paneRights } = useUserPane({ startFirstAvailableModuleFound });

watch(
    () => dropdownEntity.value,
    () => {
        if (dropdownEntity.value) {
            closeDropdownOnStart();
        }
    },
    { immediate: true }
);
</script>
<style lang="scss">
.tp-dfwv {
    z-index: 999;
    bottom: 0;
    top: auto !important;
}

.dropdown-entity-input {
    padding: 2px 12px;
    background-color: var(--grayscale-gray-light);
    border-radius: 8px;
    display: flex;
    cursor: pointer;
}

.--empty {
    .fsb-dropdown__trigger {
        width: 72px !important;
        height: 32px !important;
        padding-left: 24px;

        * {
            height: 100%;
        }
    }
}

.right-nav {
    gap: 8px;

    .fsb-dropdown-linked-input {
        border: none;
        background: transparent;
        justify-content: right !important;
        &__label {
            margin-right: 4px;
            font-weight: 500 !important;
            font-size: 12px !important;
            color: var(--grayscale-black) !important;
        }
    }

    svg path {
        fill: none !important;
    }
}

.hub-navbar {
    height: 60px;

    .fsb-dropdown__trigger {
        width: 100%;
        height: 100%;
    }
    .lmp-version-selector {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        & .fsb-icon {
            margin-left: 5px;
        }
    }
    .app-nav-item {
        a {
            color: var(--grayscale-gray-medium) !important;
            position: relative;
            font-weight: 500 !important;
            font-size: 12px;
            line-height: 14px;
            &:hover {
                color: var(--grayscale-gray-dark) !important;
            }
        }
        .active a {
            color: var(--grayscale-black) !important;
        }
    }
    .navbar-nav:not(.right-nav) {
        .fsb-dropdown__line {
            width: 100%;
            &--selected {
                background-color: var(--grayscale-black) !important;
                .fsb-dropdown__line__label {
                    color: #fff !important;
                }
                .fsb-dropdown__line__no-checkbox {
                    cursor: not-allowed;
                    background-color: var(--grayscale-black) !important;
                    color: #fff !important;
                }
            }

            &__label {
                display: inline-block;
                line-height: 5rem;
            }
        }
    }
}
</style>
