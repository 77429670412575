import { Pane } from 'tweakpane';
import { Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuthStore, useGlobal } from '../store';
import useUserRights, { SERVICES, ServiceStatus } from './userRights';

interface BrandServices {
    [brand: string]: ServiceStatus;
}

export default function useUserPane({
    startFirstAvailableModuleFound,
}: {
    switchApp: (service: string) => void;
    startFirstAvailableModuleFound: () => void;
    setSelectedDropdownEntity: () => void;
    addAppChildren: (name: string, url: string) => void;
}) {
    const globalStore = useGlobal();
    const authStore = useAuthStore();

    const { user } = storeToRefs(authStore);
    const { selectedBrand } = storeToRefs(globalStore);
    const { userServices } = useUserRights();

    const paneRights: Ref<BrandServices> = ref({});

    const pane: Ref<Pane | null> = ref(null);

    const route = useRoute();

    const computeRights = (): BrandServices => {
        const app: BrandServices = {};

        Object.keys(userServices.value.brands).forEach((brand) => {
            app[brand] = {} as ServiceStatus;

            SERVICES.forEach((service) => {
                app[brand][service] = (userServices.value.brands as any)[brand][service as string] === true;
            });
        });
        return app;
    };

    const removePane = () => {
        [...document.querySelectorAll('.tp-dfwv')].forEach((pane) => {
            (pane as HTMLElement).style.opacity = '0';
        });
        // pane.value?.dispose();
        const oldPanes = document.querySelectorAll('.tp-dfwv');
        oldPanes.forEach((oldPane) => {
            oldPane.remove();
        });
    };

    const refreshPane = () => {
        removePane();

        paneRights.value = computeRights();

        pane.value?.refresh();

        startFirstAvailableModuleFound();
    };

    watch(
        () => route.name,
        async (newRouteName) => {
            if (newRouteName === 'home') {
                pane.value = new Pane();

                refreshPane();
            }
        },
        { immediate: true }
    );

    watch(
        [() => user.value.brands, () => selectedBrand.value],
        () => {
            refreshPane();

            startFirstAvailableModuleFound();
        },
        { deep: true, immediate: true, flush: 'sync' }
    );

    const paneOnUnmounted = () => {
        removePane();

        if (pane.value) {
            pane.value.dispose();
            pane.value = null;
        }
    };

    return {
        paneRights,
        pane,
        refreshPane,
        removePane,
        paneOnUnmounted,
    };
}
