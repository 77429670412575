import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/logoAdcleek.svg'
import _imports_1 from '@/assets/app_screen.png'


const _hoisted_1 = { class: "d-flex login-layout d-fill w-100 h-100" }
const _hoisted_2 = { class: "p-5 position-absolute logo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  src: _imports_0,
  alt: "logo"
}
const _hoisted_5 = { class: "col p-5 pb-0 m-auto" }
const _hoisted_6 = { class: "d-flex flex-column justify-content-between h-100" }
const _hoisted_7 = { class: "header-branding text-center" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "col d-flex p-0 justify-content-center" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "login-bg-cover m-5"
}
const _hoisted_12 = { class: "login-bg-cover__text" }




export default /*@__PURE__*/_defineComponent({
  __name: 'login-layout',
  props: {
    brand: {}
  },
  setup(__props: any) {



const brandLogoUrl: string = process.env.VUE_APP_BRAND_LOGO_URL;
const ressourcesUrl: string = process.env.VUE_APP_RESSOURCES_URL;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.brand)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "brand-icon",
            src: `${_unref(brandLogoUrl)}/${_ctx.brand}--fullsize.svg`,
            alt: "logo"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("img", _hoisted_4))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: _unref(brandLogoUrl) + 'stellantis--fullsize.svg',
              alt: ""
            }, null, 8, _hoisted_8)
          ]),
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.brand)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "brand-image",
            src: `${_unref(ressourcesUrl)}/${_ctx.brand}/lmpImg/backgroundLogin.jpg`
          }, null, 8, _hoisted_10))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('loginCoverMessage')), 1),
            _cache[0] || (_cache[0] = _createElementVNode("img", {
              src: _imports_1,
              alt: "app_screen"
            }, null, -1))
          ]))
    ])
  ]))
}
}

})