import { useApiStore, useAuthStore, useGlobal } from '@/store';
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import IframeView from '../views/iframeView/iframeView.vue';
import LoginView from '../views/login/login-view.vue';
import UpdatePwdView from '../views/updatePwd/index-update-pwd.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: IframeView,
    },
    {
        path: '/:brand?',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/reset/:resetMdpToken/:brand?',
        name: 'resetPassword',
        component: UpdatePwdView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, _from, next) => {
    const globalStore = useGlobal();
    const apiStore = useApiStore();
    const authStore = useAuthStore();
    authStore.init();

    if (to.name === 'user-token') authStore.isLogged = true;
    if (authStore.isLogged) {
        if (to.name !== 'home') return next({ name: 'home' });
        await apiStore.init();
    } else if (to.name !== 'login' && to.name !== 'resetPassword') {
        return next({ name: 'login' });
    } else if (to.params.brand) {
        await globalStore.getBrandConfig([to.params.brand.toString() || ''], true);
        if (!globalStore.selectedBrand) return next({ name: 'login', params: {} });
    }

    next();
});

export default router;
