import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "support-form" }
const _hoisted_2 = { class: "fsb-typo-para-regular" }

import { useApiStore } from '@/store';
import { computed, inject, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'SupportModal',
  props: {
    showModal: { type: Boolean }
  },
  emits: ['update:showModal'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const { t } = useI18n({ useScope: 'global' });
const snackBar: { open(variant: string, text: string, delay?: number): void } | undefined = inject('snackBar');

const apiStore = useApiStore();

const formData = reactive({ subject: '', message: '', files: [] as File[] });

const loadingSendMessage = ref(false);

const disableSendButton = computed(() => formData.subject.length === 0 || formData.message.length === 0);

const updateSubject = ({ value }: { value: string }) => {
    formData.subject = value;
};

const sendMessage = async () => {
    if (!disableSendButton.value) {
        const formDataToSend = new FormData();
        formDataToSend.append('subject', formData.subject);
        formDataToSend.append('textMail', formData.message);
        formData.files.forEach((file, index) => formDataToSend.append(`files[${index}]`, file));
        try {
            loadingSendMessage.value = true;
            await apiStore.sendSupportMessage(formDataToSend);
            closeModal();
            snackBar?.open('success', t('messageSentSuccessfully'));
        } catch (error) {
            console.log(error);
        } finally {
            loadingSendMessage.value = false;
        }
    }
};

const closeModal = () => {
    emit('update:showModal', false);
    Object.assign(formData, { subject: '', message: '', files: [] });
};

return (_ctx: any,_cache: any) => {
  const _component_simple_input = _resolveComponent("simple-input")!
  const _component_fsb_textarea = _resolveComponent("fsb-textarea")!
  const _component_fsb_upload_file = _resolveComponent("fsb-upload-file")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    "modal-show": _ctx.showModal,
    title: _unref(t)('modalSupportTitle'),
    "primary-label": _unref(t)('send'),
    "on-primary-button-click": sendMessage,
    "is-primary-button-disabled": disableSendButton.value || loadingSendMessage.value,
    "load-primary-button-spinner": loadingSendMessage.value,
    "show-secondary-button": false,
    "tertiary-label": _unref(t)('cancel'),
    "on-tertiary-button-click": closeModal,
    onCloseModal: closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)('modalSupportHelp')), 1),
        _createVNode(_component_simple_input, {
          label: _unref(t)('modalSupportSubject'),
          required: true,
          "onUpdate:value": updateSubject
        }, null, 8, ["label"]),
        _createVNode(_component_fsb_textarea, {
          value: formData.message,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((formData.message) = $event)),
          label: _unref(t)('message'),
          required: ""
        }, null, 8, ["value", "label"]),
        _createVNode(_component_fsb_upload_file, {
          "onUpdate:fileList": _cache[1] || (_cache[1] = (fileList) => (formData.files = fileList))
        })
      ])
    ], undefined, true),
    _: 1
  }, 8, ["modal-show", "title", "primary-label", "is-primary-button-disabled", "load-primary-button-spinner", "tertiary-label"]))
}
}

})