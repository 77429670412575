import { useAuthStore } from '@/store/auth';
import axios from 'axios';
import { defineStore } from 'pinia';
import { useGlobal } from './global';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_GW_FRONT_LMP_URL,
});

export const useApiStore = defineStore('useApiStore', {
    state: () => ({
        brands: [] as any[],
    }),
    actions: {
        async checkAlive() {
            const authStore = useAuthStore();
            try {
                await this.tokenIsValid();
                await authStore.checkTokenValidity();
                setTimeout(() => {
                    this.checkAlive();
                }, 600000);
            } catch {
                authStore.logout();
            }
        },

        async setBrands(): Promise<any> {
            try {
                this.brands = (await axiosInstance.get('/users/authenticated/brands')).data;

                const globalStore = useGlobal();
                await globalStore.getBrandConfig(this.brands.map((brand) => brand.brand));
            } catch (error) {
                console.error(error);
            }
        },
        tokenIsValid(): Promise<any> {
            return axiosInstance.get('/users/alive');
        },
        async init(): Promise<string | Error> {
            const authstore = useAuthStore();
            try {
                await this.checkAlive();
                const askForNewToken: string = localStorage.getItem('askForNewTokenDate') || '';
                const askForNewTokenDate = new Date(askForNewToken);
                authstore.askForNewTokenDate = askForNewTokenDate;

                this.setBrands();
                return Promise.resolve('Ok');
            } catch (e) {
                authstore.logout();
                return Promise.reject(e);
            }
        },
        async sendSupportMessage(formData: FormData) {
            await axiosInstance.post('/support/api/v2/support', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        },
    },
});

axiosInstance.interceptors.request.use(
    async (config) => {
        // Get token from localStorage
        const authtore = useAuthStore();

        const accessToken = authtore.getToken();
        const auth = `Bearer ${accessToken}`;

        // If token is present add it to request's Authorization Header
        if (accessToken) {
            if (config.headers) {
                config.headers.authorization = auth;
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const authtore = useAuthStore();

        const { config, response } = error;

        if (response && response.status === 401) {
            authtore.logout();
        }
        return Promise.reject(error);
    }
);
