import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "m-auto",
  style: {"width":"416px","height":"340px"}
}

import { useGlobal } from '@/store';
import chroma from 'chroma-js';
import { computed, ref } from 'vue';
import LoginLayout from '../layout/login-layout.vue';
import Login from './login-index.vue';
import ResetPwd from './reset-pwd.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'login-view',
  setup(__props) {

const globalStore = useGlobal();

const resetPassword = ref(false);
const customizationConfig = ref(globalStore.customizationByBrand[globalStore.selectedBrand]);

const buttonStyle = computed(() => {
    if (customizationConfig.value) {
        return {
            backgroundColor: `${customizationConfig.value.color} !important`,
            borderColor: `${customizationConfig.value.color} !important`,
            color: `${chroma(customizationConfig.value.color).luminance() > 0.5 ? 'var(--grayscale-black, #1f1f1f)' : 'var(--grayscale-white, #fefefe)'} !important`,
        };
    }
    return {};
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LoginLayout, {
    brand: customizationConfig.value ? _unref(globalStore).selectedBrand : undefined
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (resetPassword.value)
          ? (_openBlock(), _createBlock(ResetPwd, {
              key: 0,
              "button-style": buttonStyle.value,
              onOnSwitch: _cache[0] || (_cache[0] = ($event: any) => (resetPassword.value = false))
            }, null, 8, ["button-style"]))
          : (_openBlock(), _createBlock(Login, {
              key: 1,
              "button-style": buttonStyle.value,
              onOnSwitch: _cache[1] || (_cache[1] = ($event: any) => (resetPassword.value = true))
            }, null, 8, ["button-style"]))
      ])
    ], undefined, true),
    _: 1
  }, 8, ["brand"]))
}
}

})