<template>
    <div class="d-flex login-layout d-fill w-100 h-100">
        <div class="p-5 position-absolute logo">
            <img v-if="brand" class="brand-icon" :src="`${brandLogoUrl}/${brand}--fullsize.svg`" alt="logo" />
            <img v-else src="@/assets/logoAdcleek.svg" alt="logo" />
        </div>
        <div class="col p-5 pb-0 m-auto">
            <div class="d-flex flex-column justify-content-between h-100">
                <div>
                    <div class="header-branding text-center">
                        <img :src="brandLogoUrl + 'stellantis--fullsize.svg'" alt="" />
                    </div>
                    <slot></slot>
                </div>
            </div>
        </div>
        <div class="col d-flex p-0 justify-content-center">
            <img v-if="brand" class="brand-image" :src="`${ressourcesUrl}/${brand}/lmpImg/backgroundLogin.jpg`" />
            <div v-else class="login-bg-cover m-5">
                <div class="login-bg-cover__text">{{ $t('loginCoverMessage') }}</div>
                <img src="@/assets/app_screen.png" alt="app_screen" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue';

defineProps<{
    brand?: string;
}>();

const brandLogoUrl: string = process.env.VUE_APP_BRAND_LOGO_URL;
const ressourcesUrl: string = process.env.VUE_APP_RESSOURCES_URL;
</script>

<style lang="scss">
.login-bg-cover {
    width: 100%;
    border-radius: 24px;
    background: linear-gradient(35.06deg, #0d1c64 36.59%, #293ea3 94.35%);
    position: relative;
    overflow: hidden;

    &__text {
        width: 450px;
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        color: #fff;
        position: absolute;
        left: 72px;
        top: 72px;
    }
    img {
        position: absolute;
        bottom: -20px;
        right: -220px;
        border-radius: 24px;
    }
}
.logo {
    left: 0;
}
.login-layout {
    h4 {
        font-size: 20px;
        color: var(--bs-primary);
    }
    .coverImg {
        background-repeat: no-repeat;
        background-size: cover;
    }
    .header-branding {
        margin-bottom: 80px;
    }
}

.brand-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.brand-icon {
    height: 40px;
}
</style>
